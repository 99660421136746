'use strict';

$(document).ready(function() {
	// 0. Call fitty.js on the main logo
	var fittyInstances = fitty('.logo-fitty');
	var fittyMainLogoInstance = fittyInstances[0];
	var fittyLogoElement = fittyMainLogoInstance.element;

	var hamburgerButton = $('.hamburger');

	// 1. OffScreen Mobile Menu
	var open = false;
  hamburgerButton.click(function() {
  	open = !open;
    $('.for-off-canvas').toggleClass('open');
    $('body').toggleClass('noscroll');
    $('body').toggleClass('overflow-hidden');
    $(this).toggleClass('close');
	});

	// 2. NavBar state switcher
	var banner = $('.nav');
	var title_block = $('.site-header');

	var navbar_trigger_height = banner.offset().top + banner.height() - 70 + 20; // 70 px is height of fixed banner
	var original_title_block_padding_bottom = parseInt(title_block.css('padding-bottom'), 10);
	var banner_height = banner.height();
	var fixed = false;

	function navbar_state_switcher() {
		var banner_offset = navbar_trigger_height - parseInt($(window).scrollTop());
		if (banner_offset < 1 && !fixed) {
			// fix navbar to top of screen
			banner.addClass('fixed');
			title_block.css('padding-bottom', original_title_block_padding_bottom + banner_height);
			hamburgerButton.addClass('hamburger-fixed');
			fittyMainLogoInstance.fit();
			fixed = true;
		} else if (banner_offset > 0 && fixed ) {
			// navbar goes back to below the header image
			banner.removeClass('fixed');
			title_block.css('padding-bottom', original_title_block_padding_bottom);
			hamburgerButton.removeClass('hamburger-fixed');
			fittyMainLogoInstance.fit();
			fixed = false;
		}
	}

	// 3. Title Box Dissolver
	var md = new MobileDetect(window.navigator.userAgent);
	var isIos = md.os() === 'iOS';

	if(isIos) {
		// iOS or narrow screen width (title container has moved to bottom) - leave
		// .title_container as relatively positioned
	} else {
		// if js is running, and not iOS, set title box to fixed (css default is absolute)
		$('.title-container').css({	position: 'fixed'	});
	}

	function title_box_dissolve() {
		var bottom_of_title_box = parseInt($('.title-container').css('top'), 10) + $('.title-container').height();
		var dissolving_zone_height = 140;
		var dissolve_point = ($('.site-header').height() - $(window).scrollTop())*0.8;
		var distance_between = dissolve_point - bottom_of_title_box;

		if(distance_between > dissolving_zone_height) {
			$('.title-container').css({
				'opacity': 1,
				'display': 'block',
			});
		}	else if(distance_between < dissolving_zone_height && distance_between > 0) {
			var opacity = (dissolve_point - bottom_of_title_box) / dissolving_zone_height;
  		$('.title-container').css({
  			'opacity': opacity,
  			'display': 'block',
   	  });
			fittyMainLogoInstance.fit();
			// call fitty again, once the title container is displayed again and therefore
			// avaliable in the DOM again - when it is "display: none", fitty tries to
			// resize it to NaN, so we need to fix this once the element is displayed again.
		} else if (distance_between <= 0)  {
			$('.title-container').css({
				'opacity': 0,
				'display': 'none', // so you cant click on the link once it's disappeared
  		});
    }
  }

	var onScrollFunctions;
	if(isIos) {
		onScrollFunctions = function() {
			navbar_state_switcher();
		}
	} else {
		onScrollFunctions = function () {
			navbar_state_switcher();
			title_box_dissolve();
		}
	}

	// 4. Run the functions
	// i. Run both when page loads
	onScrollFunctions();

	// ii. Run both on scroll
	$(document).scroll(function () {
		onScrollFunctions();
	});
});
